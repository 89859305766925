import { ButtonComponent } from './../button/button.component';
import { ButtonModule } from '../button/button.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
@NgModule({
    declarations: [PageComponent],
    exports: [PageComponent, ButtonComponent],
    imports: [
        CommonModule,
        ButtonModule,
        TranslateModule,
        NzButtonModule,
        NzDropDownModule,
        NzIconModule
    ]
})
export class PageModule {}
