import { Injectable, Injector } from '@angular/core';
import { Project, ProjectPageResponse } from 'src/models/project';
import { WebApiUrlConfigService } from '../http.service';
import { LocalStorageService } from '../local-storage.service';
import { BaseRestfulApiService } from './base-api.service';

@Injectable()
export class ProjectApiService extends BaseRestfulApiService<Project> {
    constructor(
        private apiConfig: WebApiUrlConfigService,
        private localStorageService: LocalStorageService,
        injector: Injector
    ) {
        super(injector, apiConfig.projectUrl);
    }

    add(data: Project) {
        return this.http.post(`${this.apiUrl}`, data);
    }

    getByPage(index: number, size: number, name?: string) {
        return this.http.get<ProjectPageResponse>(
            name
                ? `${this.apiUrl}/PageList/${index}/${size}?name=${name}`
                : `${this.apiUrl}/PageList/${index}/${size}`
        );
    }

    imageUrl(projectId: string) {
        return this.http.getPlainText(`${this.apiUrl}/Image/${projectId}`);
    }

    getStartupMimic(projectId: string) {
        return this.http.get(`${this.apiConfig.mimicTreeUrl}/${projectId}`);
    }

    copy(project: Project) {
        return this.http.post(`${this.apiUrl}/Copy`, project);
    }

    addDemoProject(organizationId: string) {
        return this.http.post(
            `${this.apiUrl}/Demo?organizationId=${organizationId}`,
            {}
        );
    }

    resetDemoProject(projectId: string, demoProjectId: string) {
        return this.http.post(`${this.apiUrl}/Demo/Reset`, {
            projectId,
            demoProjectId,
            createBy: this.localStorageService.currentUserId
        });
    }

    deleteByorganization(organizationId: string) {
        return this.http.delete(
            `${this.apiUrl}/ProjectByOrganizationId/${organizationId}`
        );
    }

    publishDemoProject(projectId: string) {
        return this.http.post(
            this.apiUrl + `/PublishDemoProject/${projectId}`,
            {}
        );
    }

    withdrawDemoProject(demoProjectId: string) {
        return this.http.post(
            this.apiUrl + `/WithdrawDemoProject/${demoProjectId}`,
            {}
        );
    }

    export(projectId: string, isExportSharedLibrary: boolean) {
        return this.http.getDownload(
            this.apiUrl +
                `/Export?ProjectId=${projectId}&IsExportSharedLibrary=${isExportSharedLibrary}`
        );
    }

    async import(file: File, keepProjectId: boolean) {
        try {
            const data = new FormData();
            data.append('File', file, file.name);
            return await this.http.fetch(
                this.apiUrl + `/Import?keepProjectId=${keepProjectId}`,
                {
                    method: 'POST',
                    body: data
                }
            );
        } catch (error) {
            throw error;
        }
    }

    continueImport(filePath: string, overwrite: boolean) {
        return this.http.post(this.apiUrl + `/ContinueImport`, {
            filePath,
            overwrite
        });
    }
}
