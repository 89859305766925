import { ImagePickerModule } from './image-picker/image-picker.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ButtonModule } from './button/button.module';
import { PageModule } from './page/page.module';

@NgModule({
    exports: [ButtonModule, PageModule, ImagePickerModule]
})
export class MMModule {
    static forRoot(): ModuleWithProviders<MMModule> {
        return {
            ngModule: MMModule
        };
    }
}
