import { MMModule } from './../components/mm.module';
import { AuthService } from './../auth/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { TranslateUniversalLoader } from '../utils';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared.module';
registerLocaleData(en);

@NgModule({
    declarations: [AppComponent],
    imports: [
        SharedModule,
        BrowserModule,
        MMModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(ROUTES, {
            useHash: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateUniversalLoader
            }
        })
    ],
    bootstrap: [AppComponent],
    providers: [{ provide: NZ_I18N, useValue: en_US }]
})
export class AppModule {}
