import { Injector } from '@angular/core';
import { HttpRequestManagerService } from '../http.service';
import { HttpParams } from '@angular/common/http';
import { OrganizationQuery } from '../../models/organization';

export class BaseRestfulApiService<T, NewT = T, EditT = T> {
    protected http: HttpRequestManagerService;

    constructor(injector: Injector, protected apiUrl: string) {
        this.http = injector.get(HttpRequestManagerService);
    }

    getAll() {
        return this.http.get<T[]>(this.apiUrl);
    }

    getById(id: string) {
        return this.http.get<T>(`${this.apiUrl}/${id}`);
    }

    add(data: NewT) {
        return this.http.post(this.apiUrl, data);
    }

    edit(data: EditT) {
        return this.http.put(this.apiUrl, data);
    }

    delete(id: string) {
        return this.http.delete(`${this.apiUrl}/${id}`);
    }
}
