import { Injectable, Injector } from '@angular/core';
import { WebApiUrlConfigService } from '../http.service';
import { BaseRestfulApiService } from './base-api.service';
import {
    Organization,
    NewOrganization,
    EditOrganization,
    OrganizationQuery
} from '../../models/organization';

@Injectable()
export class OrganizationApiService extends BaseRestfulApiService<
    Organization,
    NewOrganization,
    EditOrganization
> {
    constructor(apiConfig: WebApiUrlConfigService, injector: Injector) {
        super(injector, apiConfig.organizationUrl);
    }

    queryBy(model: OrganizationQuery) {
        return this.http.get<any[]>(`${this.apiUrl}/query`, { ...model });
    }
}
