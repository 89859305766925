import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ImagePickerComponent } from './image-picker.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [ImagePickerComponent],
    exports: [ImagePickerComponent],
    imports: [CommonModule, TranslateModule, NzButtonModule, NzIconModule]
})
export class ImagePickerModule {}
