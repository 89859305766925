import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctTime } from 'rxjs-extension/operators';

@Injectable()
export class MessageService {
    private messageSubject: Subject<messageBody>;
    constructor(
        private translate: TranslateService,
        private message: NzMessageService
    ) {
        this.messageSubject = new Subject<messageBody>();
        this.messageSubject.pipe(distinctTime(1000)).subscribe(message => {
            this.translate.get(message.translateKey).subscribe(res => {
                this.message.create(message.type, res, {
                    nzDuration: message.duration
                });
            });
        });
    }
    success(translateKey: string, duration: number = 3000) {
        this.messageSubject.next({
            type: 'success',
            translateKey,
            duration
        });
    }
    error(translateKey: string, duration: number = 3000) {
        this.messageSubject.next({
            type: 'error',
            translateKey,
            duration
        });
    }
    operationSuccess(duration: number = 3000) {
        this.messageSubject.next({
            type: 'success',
            translateKey: 'Tips.OperationSuccess',
            duration
        });
    }
    operationFailed(duration: number = 3000) {
        this.messageSubject.next({
            type: 'error',
            translateKey: 'Tips.OperationFailed',
            duration
        });
    }
}

interface messageBody extends Object {
    type: 'success' | 'info' | 'warning' | 'error' | 'loading';
    translateKey: string;
    duration: number;
}
