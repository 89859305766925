export const en = {
    WeChat: {
        BindInfo: 'Bind Info',
        Avatar: 'Avatar',
        NickName: 'Nick Name',
        Unbind: 'Unbind WeChat',
        Gender: 'Gender',
        Location: 'Location',
        ConfirmUnbind: 'Confirm to unbind?'
    },
    Shared: {
        AppName: 'SWAN SCADA',
        UsersProject: "'s project"
    },
    Login: {
        Welcome: 'Welcome',
        WelcomeContent: 'Welcome to ',
        ResetPasswordTitle: 'Please Reset your initial password',
        ForgotPasswordTitle: 'Get your password back with Email',
        Continue: 'Continue',
        ResetPassword: {
            New: 'New Password',
            Repeat: 'Repeat Password'
        },
        LoginForm: {
            UserName: 'UserName',
            Email: 'Email',
            Password: 'Password',
            LoginButton: 'Login',
            RememberMe: 'Remember me',
            ForgotPassword: 'Forgot password',
            Register: 'register now!',
            ErrorHint: {
                EmptyUserName: 'Please input your username!',
                EmptyPassword: 'Please input your Password!',
                DifferentPassword: 'Confirm the passwords are same!',
                PasswordLengthError:
                    'Password length is at least 6 characters!',
                EmailError: 'Wrong Email Address'
            }
        }
    },
    TopBar: {
        User: {
            Title: 'Welcome, ',
            ChangePassword: {
                Title: 'Change Password',
                CurrentPassword: 'Current Password',
                NewPassword: 'New Password',
                NewPasswordRepeat: 'Confirm Password'
            },
            Logout: 'Logout'
        },
        Language: {
            Title: 'Please choose your language'
        },
        More: {
            Title: 'More'
        }
    },
    Errors: {
        401: 'login status expired, please login again',
        403: 'You do not have permission to use this feature',
        500: 'System Error',
        ProjectNoMimic: 'The project has not config start mimic!',
        1014: 'Organization name already exists'
    },
    Menu: {
        ProjectManagement: 'Project Management',
        OrganizationManagement: 'Organization Management',
        UserManagement: 'User Management',
        RoleManagement: 'Role Management',
        AppDownload: 'App Download'
    },
    ApiErrors: {
        1001: 'User name or password wrong',
        1002: 'Role already exist',
        1003: 'Role not exist',
        1004: 'Organization not exist',
        1005: 'Change password failed',
        1006: 'User not exist',
        1007: 'Email not exist',
        1008: 'There is a user under this role and cannot be deleted',
        1009: 'There is a user under this organization and cannot be deleted',
        1010: 'Invalid Email address',
        1011: 'Duplicate UserName',
        1012: 'Invalid UserName or Password',
        1013: 'Empty UserName',
        1014: 'Organization name already exists',
        1047: 'Project name exists',
        1051: 'Project not exists',
        1052: 'Demo project cannot be deleted, please recall first',
        1061: 'Import failed: local project version is higher than server',
        1062: 'Import failed, please check the import file'
    },
    RoleManagement: {
        Title: 'Role Management',
        Role: {
            RoleName: 'Role Name',
            Permission: 'Permission'
        }
    },
    UserManagement: {
        Title: 'User Management',
        ResetPassword: 'Reset Password',
        SelectRole: 'Select role',
        User: {
            Role: 'Role',
            Email: 'Email',
            Name: 'Name',
            UserName: 'User Name',
            Organization: 'Organization',
            LatestOnlineTime: '最近在线时间'
        }
    },
    ProjectManagement: {
        Title: 'Project Management',
        Info: 'Info',
        Edit: 'Edit',
        Copy: 'Copy',
        Share: 'Share',
        Delete: 'Delete',
        Reset: 'Reset',
        Project: {
            Name: 'Project Name',
            Address: 'Project Address',
            Contact: 'Project Contact',
            ContactNo: 'Project Contact No',
            Description: 'Project Description',
            Image: 'Project Image'
        },
        ResetConfirm:
            'Are you sure to reset this project to its initial state?',
        CantDelete: "Can't delete default projects",
        Publish: 'Publish',
        Recall: 'Recall',
        Import: 'Import',
        Export: 'Export',
        IfIncludeSharedLibrary: 'Include shared library?',
        IfOverwrite: 'Projetc already exists, overwrite it?',
        ImportFileTypeError: 'Only supports .zip file'
    },
    Common: {
        Table: {
            Add: 'Add',
            Edit: 'Edit',
            Copy: 'Copy',
            Delete: 'Delete',
            DeleteAlert: 'Are you sure delete this?',
            DeleteInfo: '',
            Action: 'Operations'
        },
        Add: 'Add',
        Edit: 'Edit',
        Search: 'Search',
        Delete: 'Delete',
        DeleteAlert: 'Are you sure delete this?',
        DeleteInfo: '',
        Action: 'Operations',
        ChooseFile: 'Choose File',
        Confirm: 'Confirm',
        Cancel: 'Cancel',
        Yes: 'Yes',
        No: 'No',
        DradUploadTips: 'Click or drag file to this area to upload',
        Upload: 'Upload',
        Male: 'Male',
        Female: 'Female',
        Unknown: 'Unknown',
        WeChatBind: 'WeChat Binding',
        ImportPolicy: 'Project ID policy',
        ImportKeepOldId:
            'Keep the project ID unchanged, if the same project ID exists, it will be overwritten',
        ImportGenNewId: 'Generate new project ID'
    },
    Tips: {
        SendEmailSucceed: 'A mail has been sent, please check',
        OperationSuccess: 'Operation Success',
        OperationFailed: 'Operation Failed',
        LoginSuccess: 'Login Success',
        CopyLinkSuccess: 'Link Copy Success',
        ResetPasswordSucceed: 'Reset Password Success',
        ImportSucceed: 'Import Success'
    },
    ErrorTips: {
        TrialVersion:
            'This is a trial version. You will be forced to log out after two hours',
        TrialExpires: 'Trial time expires, please login again',
        UserNameNoSpace: 'User Name cannot contain space!',
        UserNameRegError:
            'Only support combination of numbers and letters, and the length is 2 to 20 characters'
    },
    OrganizationManagement: {
        Title: 'Organization Management',
        Add: 'Add',
        Oraganization: {
            Name: 'Name',
            Contact: 'Contact',
            Email: 'Email',
            PhoneNumber: 'Phone Number',
            Comments: 'Comments'
        },
        Emails: 'Email format not right'
    },
    App: {
        iOS: 'iOS',
        Android: 'Android',
        Wechat: 'Wechat mini program',
        Waiting: 'Stay tuned'
    }
};
