import { LocalStorageService, LocalStorageKeys } from './local-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RoleFunctionService {
	constructor(private localStorageService: LocalStorageService) {
	}
	get functionAuth() {
		var functionAuthStr = this.localStorageService.getItem(LocalStorageKeys.functionAuth);
		if (!functionAuthStr) {
			return {}
		}

		return JSON.parse(functionAuthStr);
	}
}