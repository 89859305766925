import { Injectable } from '@angular/core';
import { HttpRequestManagerService, WebApiUrlConfigService } from '../http.service';
import { map } from 'rxjs/operators';
import { RoleTreeData, RoleTreeResponse, RoleTree } from '../../models/role';
import { Observable } from 'rxjs';

@Injectable()
export class SystemApiService {
  constructor(
    private http: HttpRequestManagerService,
    private apiConfig: WebApiUrlConfigService  ) { }
  getRoleTree(): Observable<RoleTreeData> {
    return this.http.get<RoleTreeResponse>(this.apiConfig.systemConfigUrl).pipe(
      map(val => {
        return {
          id: val.id,
          functionTree: JSON.parse<RoleTree>(val.functionTree),
          enFunctionTree: JSON.parse<RoleTree>(val.enFunctionTree)
        };
      })
    )
  }
}