<button nz-button (click)="selectFile()">
  <span><i nz-icon nzType="upload"></i> {{ 'Common.ChooseFile' | translate }}</span>
</button>
<div class="image-container" *ngIf="value">
  <span class="upload-delete" (click)="clearImage()">
    <i nz-icon nzType="close" theme="outline"></i>
  </span>
  <img class="upload-image" [src]="value || ''" alt="" />
</div>

<input onkeydown="if(event.keyCode==13){event.keyCode=0;event.returnValue=false;}" type="file" hidden #fileElement
  accept="image/*" (change)="onChange($event)" />
