import { JsonFileService } from './json-file.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, bufferTime, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalStorageService, LocalStorageKeys } from './local-storage.service';
import { MessageService } from './message.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    private funcSubject = new Subject<string>();
    constructor(
        private modalService: NzModalService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private messageService: MessageService,
        private message: NzMessageService,
        private jsonFile: JsonFileService,
        private translate: TranslateService
    ) {
        this.funcSubject
            .pipe(
                bufferTime(500),
                filter(funcs => funcs.length > 0)
            )
            .subscribe(funcs => this.error403(funcs));
    }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const currentUser = this.localStorageService.getItem(
            LocalStorageKeys.currentUser
        );
        if (currentUser) {
            const token = JSON.parse(currentUser).token;
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token
                    }
                });
            }
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400: {
                        const errorCode =
                            (error.error && error.error.errorCode) ||
                            (error.error && error.error.Code) ||
                            findCode(error.error);
                        if (errorCode) {
                            this.messageService.error('ApiErrors.' + errorCode);
                        }
                        break;
                    }
                    case 401: {
                        if (window['__trialVersion'] === true) {
                            this.translate
                                .get('ErrorTips.TrialExpires')
                                .subscribe((trialExpiresMessage: string) => {
                                    this.modalService.error({
                                        nzTitle: trialExpiresMessage,
                                        nzClosable: false,
                                        nzOnOk: () => {
                                            this.localStorageService.removeItem(
                                                LocalStorageKeys.currentUser
                                            );
                                            this.router.navigate(['/login']);
                                        }
                                    });
                                });
                        } else {
                            this.messageService.error('Errors.401');
                            this.localStorageService.removeItem(
                                LocalStorageKeys.currentUser
                            );
                            this.router.navigate(['/login']);
                        }

                        break;
                    }
                    case 403: {
                        if (error.error.function) {
                            this.funcSubject.next(error.error.function);
                        } else {
                            this.messageService.error('Errors.403');
                        }
                        break;
                    }
                    case 500: {
                        this.messageService.error('Errors.500');
                        break;
                    }
                    case 0: {
                        this.messageService.error('Errors.500');
                    }
                }

                return throwError(error);
            })
        );
    }

    error403(funcs: string[]) {
        this.jsonFile.get('./assets/functions.json').then(text => {
            const funcNames = funcs
                .map(
                    func => text[func] && text[func][this.translate.currentLang]
                )
                .join(', ');
            const tip = {
                en: `Sorry, you don't have right of [${funcNames}]`,
                ch: `您没有[${funcNames}]权限`
            };
            this.message.error(tip[this.translate.currentLang]);
        });
    }
}

interface IResult {
    errors: {
        [key: string]: string[];
    };
}

interface IError {
    Code: string;
    Content: string;
}

export function findCode(result: IResult) {
    if (result.errors) {
        return Object.values(result.errors)
            .map(errors => {
                const parsedErrors = errors.map(error =>
                    JSON.parse<IError>(error)
                );
                const firstErrorContainsCode = parsedErrors.find(
                    parsedError => !!parsedError.Code
                );
                return firstErrorContainsCode && firstErrorContainsCode.Code;
            })
            .filter(code => code)[0];
    }
}
