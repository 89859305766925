import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { LocalStorageService, LocalStorageKeys } from './local-storage.service';
import { MessageService } from './message.service';
import { findCode } from './http-interceptor.service';

/* api interface url configuration */
@Injectable()
export class WebApiUrlConfigService {
    baseApiUrl = environment.baseApiUrl;
    baseScadaApiUrl = environment.baseScadaApiUrl;
    scadaDesignerUrl = environment.scadaDesignerUrl;
    scadaRuntimeUrl = environment.scadaRuntimeUrl;

    //Account
    accountUrl = this.baseApiUrl + 'api/Account/';
    loginUrl = this.accountUrl + 'Login/';
    functionsUrl = this.accountUrl + 'Functions/';

    //Organization
    organizationUrl = this.baseApiUrl + 'api/Organization';

    //SystemConfig
    systemConfigUrl = this.baseApiUrl + 'api/SystemConfig';

    //Role
    roleUrl = this.baseApiUrl + 'api/Role';

    //User
    userUrl = this.baseApiUrl + 'api/User';

    //Project
    projectUrl = this.baseScadaApiUrl + 'api/Project';
    mimicTreeUrl = this.baseScadaApiUrl + 'api/MimicTree';

    //Command
    commandUrl = this.baseScadaApiUrl + 'api/Command';

    // WeChat
    weChatUrl = this.baseApiUrl + 'api/WeChat';
}

type Params =
    | HttpParams
    | {
          [param: string]: string | string[];
      };

/* asynchronous request helper class */
@Injectable()
export class HttpRequestManagerService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private messageService: MessageService
    ) {}

    get<T = any>(url: string, params: Params = null): Observable<T> {
        return this.http.get<T>(url, {
            params
        });
    }

    getPlainText(url: string, params: Params = null) {
        return this.http.get(url, {
            params,
            responseType: 'text'
        });
    }

    getHtml(url: string) {
        return this.http.get(url);
    }

    post(url: string, parametersModel: any, options = {}): Observable<any> {
        return this.http.post(url, parametersModel, options);
    }

    download(url: string, parametersModel: any): Observable<any> {
        return this.http.post(url, parametersModel, {
            responseType: 'blob'
        });
    }

    getDownload(url: string) {
        return this.http.get(url, {
            responseType: 'blob'
        });
    }

    delete(url: string): Observable<any> {
        return this.http.delete(url, {});
    }

    put(url: string, parametersModel: any): Observable<any> {
        return this.http.put(url, parametersModel, {});
    }

    fetch(url: string, options: RequestInit) {
        const currentUser = this.localStorageService.getItem(
            LocalStorageKeys.currentUser
        );
        if (currentUser) {
            const token = JSON.parse(currentUser).token;
            if (token) {
                options.headers = {
                    Authorization: 'Bearer ' + token,
                    ...(options.headers || {})
                };
            }
        }
        return fetch(url, options).then(async response => {
            if (response.ok) {
                return await response.json();
            } else {
                const error = await response.json();
                let errorCode = error.Code;
                if (!errorCode) {
                    errorCode = error.errorCode;
                }
                this.messageService.error('ApiErrors.' + errorCode);
                throw error;
            }
        });
    }
}
