import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    LocalStorageService,
    LocalStorageKeys,
    ProductService
} from 'src/services';
import { NzI18nService, en_US, zh_CN } from 'ng-zorro-antd/i18n';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        private productService: ProductService,
        private translate: TranslateService,
        private localStorageService: LocalStorageService,
        private nzI18n: NzI18nService,
        private titleService: Title,
        private router: Router,
        private authService: AuthService
    ) {
        this.setLang();
        this.setTitle();
        this.heartbeat();
        this.checkUserIsInitPassword();
    }
    ngOnInit() {
        // 浏览器关闭监听
        // window.onbeforeunload = () => {
        //     const currentUser = JSON.parse(
        //         this.localStorageService.getItem(LocalStorageKeys.currentUser)
        //     );
        //     if (currentUser && currentUser.isInitPassword) {
        //         this.localStorageService.removeItem(
        //             LocalStorageKeys.currentUser
        //         );
        //     }
        // };
    }

    private checkUserIsInitPassword() {
        const currentUser = JSON.parse(
            this.localStorageService.getItem(LocalStorageKeys.currentUser)
        );
        if (currentUser && currentUser.isInitPassword) {
            this.router.navigate(['/login'], {
                queryParams: { status: 0 }
            });
        }
    }
    private setLang() {
        this.translate.addLangs(['en', 'ch']);
        this.translate.setDefaultLang('en');
        this.nzI18n.setLocale(en_US);
        let lang = this.localStorageService.getItem(
            LocalStorageKeys.currentLanguage
        );
        if (!lang) {
            lang = navigator.language.toLowerCase() === 'zh-cn' ? 'ch' : 'en';
            this.localStorageService.setItem(
                LocalStorageKeys.currentLanguage,
                lang
            );
        }
        this.translate.use(lang);
        this.nzI18n.setLocale(lang === 'en' ? en_US : zh_CN);
    }

    private setTitle() {
        this.translate.get('Shared.AppName').subscribe(res => {
            this.titleService.setTitle(res);
        });
    }

    private heartbeat() {
        const domains = ['mm-software.cn', 'wago.com'];
        const disableHeartbeat =
            domains.filter(domain => window.location.host.includes(domain))
                .length > 0;
        if (disableHeartbeat === false) {
            this.productService.heartbeat();
        }
    }
}
