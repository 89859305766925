import { MMModule } from './../components/mm.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    WebApiUrlConfigService,
    LocalStorageService,
    HttpRequestManagerService,
    MessageService,
    HttpInterceptorService,
    SystemApiService,
    RoleFunctionService,
    ProductService,
    JsonFileService
} from '../services';
import { AuthService } from '../auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
@NgModule({
    imports: [
        CommonModule,
        NzFormModule,
        NzTableModule,
        NzDividerModule,
        NzModalModule,
        NzSpinModule,
        NzTreeModule,
        NzEmptyModule,
        NzPaginationModule,
        NzUploadModule,
        NzButtonModule,
        NzLayoutModule,
        NzIconModule,
        NzDropDownModule,
        NzToolTipModule,
        NzPopoverModule,
        NzInputModule,
        NzSelectModule,
        NzMessageModule,
        MMModule,
        HttpClientModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        NzFormModule,
        NzTableModule,
        NzDividerModule,
        NzModalModule,
        NzSpinModule,
        NzTreeModule,
        NzEmptyModule,
        NzPaginationModule,
        NzUploadModule,
        NzButtonModule,
        NzLayoutModule,
        NzIconModule,
        NzDropDownModule,
        NzToolTipModule,
        NzPopoverModule,
        NzInputModule,
        NzSelectModule,
        NzCheckboxModule,
        NzMessageModule,
        FormsModule,
        MMModule,
        ReactiveFormsModule
    ],
    providers: [
        AuthService,
        LocalStorageService,
        RoleFunctionService,
        ProductService,
        WebApiUrlConfigService,
        HttpRequestManagerService,
        SystemApiService,
        MessageService,
        JsonFileService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }
    ]
})
export class SharedModule {}
