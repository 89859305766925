import { BaseRestfulApiService } from './base-api.service';
import { Injector, Injectable } from '@angular/core';
import { WebApiUrlConfigService } from '../http.service';
import { SystemApiService } from './system-api.service';
import { Role, NewRole } from 'src/models/role';
import { Observable } from 'rxjs';
import { ProjectPageResponse } from '../../models/project';

@Injectable()
export class RoleApiService extends BaseRestfulApiService<Role, NewRole> {
    constructor(
        apiConfig: WebApiUrlConfigService,
        private systemApiService: SystemApiService,
        injector: Injector
    ) {
        super(injector, apiConfig.roleUrl);
    }

    getRoleTree() {
        return this.systemApiService.getRoleTree();
    }

    getByUser(userId: string): Observable<Array<string>> {
        return this.http.get(`${this.apiUrl}/User/${userId}`);
    }

    query(roleName?: string) {
        return this.http.get(
            roleName
                ? `${this.apiUrl}/query?name=${roleName}`
                : `${this.apiUrl}/query`
        );
    }
}
