<div class="action">
  <span class="title">{{ title }}</span>
  <div>
    <ng-content select=".query-box"></ng-content>
    <button nz-button type="primary" class="mm-button"
      onkeydown="if(event.keyCode==13){event.keyCode=0;event.returnValue=false;}" (click)="add.emit($event)"
      *ngIf="canAdd && (menuItems?.length === 0 || menuItems === null)">
      + {{ 'Common.Add' | translate }}
    </button>
    <button nz-button type="primary" class="mm-button"
      onkeydown="if(event.keyCode==13){event.keyCode=0;event.returnValue=false;}" nz-dropdown [nzDropdownMenu]="menu"
      (click)="add.emit($event)" *ngIf="canAdd && menuItems?.length > 0">
      + {{ 'Common.Add' | translate }}
    </button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item *ngFor="let item of menuItems">
          <a class="menu-item" (click)="clickMenu(item.key)">
            <i nz-icon *ngIf="item.icon" [nzType]="item.icon" nzTheme="outline"></i>
            {{ item.label | translate }}</a>
        </li>
      </ul>
    </nz-dropdown-menu>

    <button nz-button type="primary" class="mm-button" [disabled]="!canDelete"
      onkeydown="if(event.keyCode==13){event.keyCode=0;event.returnValue=false;}" (click)="delete.emit($event)"
      *ngIf="allowDelete">
       {{ 'Common.Delete' | translate }}</button>
  </div>

</div>
<hr class="hr" color="#e1e1e1" size="1" />
<ng-content></ng-content>
