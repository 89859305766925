import { OnDestroy, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
export abstract class BaseControlValueAccessor<T>
    implements ControlValueAccessor, OnDestroy {
    private value$: BehaviorSubject<T>;
    private subscriptions: Array<Subscription>;

    get value(): any {
        return this.value$.value;
    }

    set value(value: any) {
        if (value !== this.value$.value) {
            this.value$.next(value);
        }
    }

    protected constructor(initValue: T) {
        this.value$ = new BehaviorSubject<T>(initValue);
        this.subscriptions = [];
    }

    ngOnDestroy() {
        if (this.subscriptions && this.subscriptions.length > 0) {
            this.subscriptions.forEach(subscription =>
                subscription.unsubscribe()
            );
        }
        this.subscriptions = null;
    }

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: (_: T) => void): void {
        const subscription = this.value$.subscribe(fn);
        this.subscriptions.push(subscription);
    }

    registerOnTouched(fn: () => void): void {
        const subscription = this.value$.subscribe(fn);
        this.subscriptions.push(subscription);
    }
}
