import { Injectable, NgZone } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

import { TranslateService } from '@ngx-translate/core';
import {
    HttpRequestManagerService,
    WebApiUrlConfigService
} from './http.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ProductService {
    shortHeartbeatTimer = 1000 * 5;
    heartbeatTimer = 1000 * 60 * 15;
    heartbeatCount = 0;
    keepAliveUrl = this.apiConfig.baseApiUrl + 'api/Product/KeepAlive/';

    constructor(
        private zone: NgZone,
        private modalService: NzModalService,
        private translateService: TranslateService,
        private http: HttpRequestManagerService,
        private apiConfig: WebApiUrlConfigService,
        private localStorageService: LocalStorageService
    ) {}

    heartbeat() {
        const heartbeatAction = async (timer: number) => {
            await this.delay(timer);

            if (
                !this.localStorageService.currentUserId &&
                this.heartbeatCount === 0
            ) {
                return await heartbeatAction(this.shortHeartbeatTimer); // Tail call optimization
            }

            if (!this.localStorageService.currentUserId) {
                this.heartbeatCount = 0;
            }

            const result = await this.keepAlive();
            // true means trial version, 2-hour usage limit, heartbeat request every 15 minutes
            // false means licensed version, no time limit, no more heartbeat request

            this.heartbeatCount++;
            if (this.heartbeatCount === 1 && result === true) {
                const trialVersionMessage = await this.getTrialVersionWarning();
                this.zone.run(() => {
                    this.modalService.warning({
                        nzTitle: trialVersionMessage,
                        nzClosable: false
                    });
                });
            }

            window['__trialVersion'] = result === true;
            if (result === true) {
                return await heartbeatAction(this.heartbeatTimer); // Tail call optimization
            }
        };

        this.zone.runOutsideAngular(async () => {
            await heartbeatAction(this.shortHeartbeatTimer);
        });
    }

    private delay(timer: number) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, timer);
        });
    }

    private async keepAlive() {
        return await this.http
            .get(`${this.keepAliveUrl}?r=${new Date().getTime()}`)
            .toPromise();
    }

    private async getTrialVersionWarning() {
        return await this.translateService
            .get('ErrorTips.TrialVersion')
            .toPromise();
    }
}
