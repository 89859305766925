import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';

import { AuthService } from './auth.service';
import { LocalStorageService, LocalStorageKeys } from 'src/services';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router, private localStorageService: LocalStorageService) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): boolean {
		const url: string = state.url;
		const currentUser = this.localStorageService.getItem(LocalStorageKeys.currentUser);
		if (url === '/login' && currentUser) {
			this.router.navigate(['/']);
			return true;
		}
		if (url === '/login') {
			return true;
		}
		return this.checkLogin(url);
	}

	checkLogin(url: string): boolean {
		const currentUser = this.localStorageService.getItem(LocalStorageKeys.currentUser);
		if (currentUser) { return true; }

		// Store the attempted URL for redirecting
		this.authService.redirectUrl = url;

		// Navigate to the login page with extras
		this.router.navigate(['/login']);
		return false;
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}
