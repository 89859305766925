import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../base-control-value-accessor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImagePickerComponent),
            multi: true
        }
    ],
    selector: 'mm-image-picker',
    templateUrl: './image-picker.component.html',
    styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent extends BaseControlValueAccessor<string> {
    @ViewChild('fileElement', { static: true }) fileElement: {
        nativeElement: HTMLInputElement;
    };

    constructor(private domSanitizer: DomSanitizer) {
        super('');
    }

    selectFile() {
        this.fileElement.nativeElement.click();
    }

    onChange(e: Event) {
        const fileInput = e.target as HTMLInputElement;
        const reader = new FileReader();
        reader.onload = () => {
            this.value = this.domSanitizer.bypassSecurityTrustUrl(
                reader.result as string
            );
        };
        reader.readAsDataURL(fileInput.files[0]);
        fileInput.value = '';
    }

    clearImage() {
        this.value = '';
    }
}
