import { OrganizationApiService } from './organization-api.service';
import { User, NewUser, EditUser } from 'src/models/user';
import { BaseRestfulApiService } from './base-api.service';
import { Injector, Injectable } from '@angular/core';
import { WebApiUrlConfigService } from '../http.service';
import { RoleApiService } from './role-api.service';

@Injectable()
export class UserApiService extends BaseRestfulApiService<
    User,
    NewUser,
    EditUser
> {
    constructor(
        apiConfig: WebApiUrlConfigService,
        injector: Injector,
        private roleApiService: RoleApiService,
        private organizationApiService: OrganizationApiService
    ) {
        super(injector, apiConfig.userUrl);
    }

    getRoles() {
        return this.roleApiService.getAll();
    }

    getOrgnizations() {
        return this.organizationApiService.getAll();
    }

    getAll(model?: any) {
        return this.http.get<any[]>(`${this.apiUrl}`, {
            ...model
        });
    }

    updateOnlineTime(userId: string) {
        return this.http.put(
            `${this.apiUrl}/UpdateOnlineTime?userId=${userId}`,
            null
        );
    }
}
