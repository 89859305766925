import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class JsonFileService {
    constructor(private http: HttpClient) {}

    public async get<T extends any>(filePath: string) {
        return await this.http.get<T>(filePath).toPromise();
    }
}
