import { Injectable } from '@angular/core';
import {
    HttpRequestManagerService,
    WebApiUrlConfigService
} from '../http.service';
import {
    LocalStorageService,
    LocalStorageKeys
} from '../local-storage.service';

@Injectable()
export class AccountApiService {
    enableMail = false;
    constructor(
        private http: HttpRequestManagerService,
        private apiConfig: WebApiUrlConfigService,
        private localStorageService: LocalStorageService
    ) {
        this.getAppSettings()
            .toPromise()
            .then(({ enableMail }) => (this.enableMail = enableMail));
    }

    changePassword(val) {
        const currentUser = JSON.parse(
            this.localStorageService.getItem(LocalStorageKeys.currentUser)
        );
        return this.http.post(this.apiConfig.accountUrl + 'ChangePassword', {
            email: (currentUser && currentUser.email) || val.email,
            ...val
        });
    }

    resetPassword(val: { userName: string; password?: string }) {
        return this.http.post(this.apiConfig.accountUrl + 'ResetPassword', val);
    }

    forgotPassword(val: { userName: string; password?: string }) {
        return this.http.post(
            this.apiConfig.accountUrl + 'ForgotPassword',
            val
        );
    }

    getAppSettings() {
        return this.http.get<{ enableMail: boolean }>(
            this.apiConfig.accountUrl + 'AppSettings'
        );
    }

    logout() {
        return this.http
            .post(this.apiConfig.accountUrl + 'Logout', {})
            .toPromise();
    }
}
