import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
    LocalStorageService,
    LocalStorageKeys,
    WebApiUrlConfigService
} from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    currentUser: {} = null;
    authInfo = {};

    // store the URL so we can redirect after logging in
    redirectUrl: string;

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private webApiUrlConfigService: WebApiUrlConfigService
    ) {
        this.currentUser = this.localStorageService.getItem(
            LocalStorageKeys.currentUser
        );
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post(`${this.webApiUrlConfigService.loginUrl}`, {
            UserName: username,
            Password: password
        });
    }

    logout(): void {
        this.localStorageService.removeItem(LocalStorageKeys.currentUser);
        this.router.navigate(['/login']);
    }

    getFunctions(): Observable<any> {
        return this.http.get(this.webApiUrlConfigService.functionsUrl);
    }
}
