export const ch = {
    WeChat: {
        BindInfo: '绑定信息',
        Avatar: '头像',
        NickName: '昵称',
        Unbind: '解除绑定',
        Gender: '性别',
        Location: '位置',
        ConfirmUnbind: '是否确认解绑？'
    },
    Shared: {
        AppName: 'SWAN SCADA云平台',
        UsersProject: '的项目'
    },
    Login: {
        Welcome: 'Welcome',
        WelcomeContent: '欢迎使用',
        ResetPasswordTitle: '请重置您的初始密码',
        ForgotPasswordTitle: '请填写邮箱地址以找回密码',
        Continue: '继续',
        ResetPassword: {
            New: '新密码',
            Repeat: '重复密码'
        },
        LoginForm: {
            UserName: '用户名',
            Email: '电子邮件地址',
            Password: '密码',
            LoginButton: '登 录',
            RememberMe: '记住我',
            ForgotPassword: '忘记密码',
            Register: '注册',
            ErrorHint: {
                EmptyUserName: '用户名不能为空！',
                EmptyPassword: '密码不能为空！',
                DifferentPassword: '密码不一致！',
                PasswordLengthError: '密码长度至少6位！',
                EmailError: '电子邮件地址错误'
            }
        }
    },
    TopBar: {
        User: {
            Title: '欢迎您，',
            ChangePassword: {
                Title: '修改密码',
                CurrentPassword: '当前密码',
                NewPassword: '新密码',
                NewPasswordRepeat: '确认新密码'
            },
            Logout: '退出登录'
        },
        Language: {
            Title: '请选择系统语言'
        },
        More: {
            Title: '更多'
        }
    },
    Errors: {
        401: '登陆超时，请重新登陆',
        403: '你没有权限使用此功能',
        500: '系统错误',
        ProjectNoMimic: '该项目未设置启动画面！',
        1014: '组织名称已存在'
    },
    Menu: {
        ProjectManagement: '项目管理',
        OrganizationManagement: '组织管理',
        UserManagement: '用户管理',
        RoleManagement: '角色管理',
        AppDownload: 'APP 下载'
    },
    ApiErrors: {
        1001: '用户名密码错误',
        1002: '该角色已存在',
        1003: '角色不存在',
        1004: '组织不存在',
        1005: '修改密码失败',
        1006: '用户不存在',
        1007: '邮箱不存在',
        1008: '该角色存在用户，无法删除',
        1009: '该组织存在用户，无法删除',
        1010: '邮件地址不合法',
        1011: '用户名重复',
        1012: '无效的用户名或密码',
        1013: '用户名不能为空',
        1014: '组织名字已存在',
        1047: '项目名称已存在',
        1051: '项目不存在',
        1052: '演示项目不能删除，请先回收',
        1061: '导入失败：项目版本高于服务器版本',
        1062: '导入失败, 请检查导入文件'
    },
    RoleManagement: {
        Title: '角色管理',
        Role: {
            RoleName: '角色名称',
            Permission: '权限'
        }
    },
    UserManagement: {
        Title: '用户管理',
        ResetPassword: '重置密码',
        SelectRole: '选择角色',
        User: {
            Role: '角色',
            Email: 'Email',
            Name: '姓名',
            UserName: '用户名',
            Organization: '组织',
            LatestOnlineTime: '最近在线时间'
        }
    },
    ProjectManagement: {
        Title: '项目管理',
        Info: '信息',
        Edit: '修改',
        Copy: '复制',
        Share: '分享',
        Delete: '删除',
        Reset: '重置',
        Project: {
            Name: '项目名称',
            Address: '项目地址',
            Contact: '项目负责人',
            ContactNo: '项目负责人电话',
            Description: '项目描述',
            Image: '项目图片'
        },
        ResetConfirm: '是否确认重置此项目到初始状态？',
        CantDelete: '初始项目无法删除',
        Publish: '发布',
        Recall: '回收',
        Import: '导入',
        Export: '导出',
        IfIncludeSharedLibrary: '是否包含共享库？',
        IfOverwrite: '项目已存在，是否覆盖？',
        ImportFileTypeError: '仅支持.zip格式'
    },
    Common: {
        Table: {
            Add: '新增',
            Edit: '修改',
            Copy: '复制',
            Delete: '删除',
            DeleteAlert: '是否确认删除？',
            DeleteInfo: '',
            Action: '操作'
        },
        Add: '新增',
        Edit: '修改',
        Search: '查询',
        Delete: '删除',
        DeleteAlert: '是否确认删除？',
        DeleteInfo: '',
        Action: '操作',
        ChooseFile: '选择文件',
        Confirm: '确认',
        Cancel: '取消',
        Yes: '是',
        No: '否',
        DradUploadTips: '点击上传或拖拽文件至此处',
        Upload: '上传',
        Male: '男',
        Female: '女',
        Unknown: '未知',
        WeChatBind: '微信绑定',
        ImportPolicy: '项目标识策略',
        ImportKeepOldId: '保持项目标识不变，如存在相同项目标识则覆盖',
        ImportGenNewId: '生成新的项目标识'
    },
    Tips: {
        SendEmailSucceed: '发送成功',
        OperationSuccess: '操作成功',
        OperationFailed: '操作失败',
        LoginSuccess: '登陆成功',
        CopyLinkSuccess: '链接复制成功',
        ResetPasswordSucceed: '重置密码成功',
        ImportSucceed: '导入成功'
    },
    ErrorTips: {
        TrialVersion: '此版本为试用版本，两小时后需要重新登录',
        TrialExpires: '试用时间到期，请重新登录',
        UserNameNoSpace: '用户名不能包含空格!',
        UserNameRegError: '仅支持数字和字母组合，且长度为2到20个字符'
    },
    OrganizationManagement: {
        Title: '组织管理',
        Add: '新增',
        Oraganization: {
            Name: '组织名称',
            Contact: '主要联系人',
            Email: '邮箱',
            PhoneNumber: '电话',
            Comments: '备注'
        },
        Emails: '邮箱格式不正确'
    },
    App: {
        iOS: 'iOS',
        Android: '安卓',
        Wechat: '微信小程序',
        Waiting: '敬请期待'
    }
};
