import { Component, Output, EventEmitter, Input } from '@angular/core';
import { InputBoolean } from 'ng-zorro-antd/core/util';

@Component({
    selector: 'mm-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent {
    @Input('mmTitle') title: string;
    @Input() @InputBoolean() canAdd: boolean;

    @Input() @InputBoolean() canDelete: boolean;

    @Input() @InputBoolean() allowDelete: boolean;

    @Input()
    menuItems: {
        key: string;
        label: string;
        icon: string;
    }[] = [];
    @Output()
    add = new EventEmitter();

    @Output()
    delete = new EventEmitter();
    @Output()
    clickMenuItem = new EventEmitter<string>();

    clickMenu(key: string) {
        this.clickMenuItem.next(key);
    }
}
