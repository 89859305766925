import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
	get currentUserId() {
		const currentUser = JSON.parse(localStorage.getItem(LocalStorageKeys.currentUser));
		return currentUser && currentUser.id;
	}
	setItem(key: LocalStorageKeys, value: string) {
		localStorage.setItem(key, value);
	}

	getItem(key: LocalStorageKeys) {
		return localStorage.getItem(key);
	}

	removeItem(key: LocalStorageKeys) {
		return localStorage.removeItem(key);
	}
}

export enum LocalStorageKeys {
	currentUser = 'currentUser',
	functionAuth ='functionAuth',
	currentLanguage = 'currentLanguage'
}
