// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseApiUrl:
        __baseApiUrl ||
        `${window.location.protocol}//${window.location.host}:8060/`,
    baseScadaApiUrl:
        __baseScadaApiUrl ||
        `${window.location.protocol}//${window.location.host}:8061/`,
    scadaDesignerUrl: __scadaDesignerUrl || '/scada/',
    scadaRuntimeUrl: __scadaRuntimeUrl || '/scada/#/runtime/',
    sharedKey: __sharedKey || '2150dfde7ef911eaa2f56dfeb386860d'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
